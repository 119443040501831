import {
  connect1,
  connect2,
  imageSlider,
  jdgen1,
  jdgen2,
  notes1,
  notes2,
  outyn1,
  outyn2,
  pView1,
  pView2,
  slider,
  suite1,
  suite2,
  wordLift1,
  wordLift2,
} from '../assets/images';
import {
  gaming,
  learning,
  graphics,
  productivity,
  health,
  goLink,
  pokemon,
  paraphraser,
  article,
  imageGenerator,
  todo,
  eventBuddy,
  geolocation,
  grimoire,
  chatBox,
  symptomChecker,
  findive,
  aiRecommend,
  ecommerce,
  code,
  dropLogo,
  widgetIcon,
} from '../assets/svgs';

import { routes } from '../router/routes';

export const products = [
  {
    id: 'jdgen-widget',
    link: '/jdgen-widget/project',
    name: 'jdgen-widget',
    title: 'JDGEN Widget',
    description:
      'A tool which aids in generating accurate job descriptions based on user input.',
    tag: 'Widget',
    icon: widgetIcon,
    title_icon: widgetIcon,
    project_overview:
      'The primary objective of JDGEN is to assist job recruiters and companies in generating accurate and tailored job descriptions for various roles they are hiring for. The widget aims to eliminate the common practice of copying and pasting job descriptions that may not fit specific roles, ensuring that the requirements and responsibilities listed are appropriate for the position being advertised.',
    isProduct: true,
    prev: null,
    next: '/connectOR/project',
    images: [
      {
        id: 1,
        src: jdgen1,
      },
      {
        id: 2,
        src: jdgen2,
      },
    ],
    date: 'August 2024',
    preview: 'https://github.com/Renaissance-Innovation-Labs/jdgen-widget',
    contributors: [{ id: 1, name: 'Chioma Otu', title: 'Frontend developer' }],
  },
  {
    id: 'connectOR',
    link: '/connectOR/project',
    name: 'connectOR',
    title: 'ConnectOR',
    description:
      'Guides users through a questionnaire to assess relationship dynamics.',
    tag: 'Drop',
    isProduct: true,
    icon: dropLogo,
    images: [
      {
        id: 1,
        src: connect1,
      },
      {
        id: 1,
        src: connect2,
      },
    ],
    prev: '/jdgen-widget/project',
    next: '/wordlift/project',
    date: 'June 2024',
    contributors: [
      { id: 1, name: 'Josephine Abel', title: 'Frontend developer' },
      { id: 2, name: 'Basil Jedidiah', title: 'Frontend developer' },
      { id: 3, name: 'Tamunodubam Tom-George', title: 'Data analyst' },
      { id: 4, name: 'Emmanuel Wogundu', title: 'Product designer' },
    ],
    title_icon: dropLogo,
    preview: 'https://ris-connector.vercel.app/',
    project_overview:
      'ConnectOR aims to help couples assess relationship dynamics through personalized questions, prompting users to provide their names, relationship status, interaction frequency, and partner details for insightful compatibility assessments.',
  },
  {
    id: 'wordlift',
    link: '/wordlift/project',
    name: 'wordlift',
    title: 'Wordlift',
    description:
      'An affirmation tool for effortless expression of love and appreciation.',
    tag: 'Drop',
    isProduct: true,
    icon: dropLogo,
    images: [
      {
        id: 1,
        src: wordLift1,
      },
      {
        id: 2,
        src: wordLift2,
      },
    ],
    prev: '/connectOR/project',
    next: '/post-note/project',
    date: 'June 2024',
    contributors: [
      { id: 1, name: 'Patrick Sile', title: 'Product designer' },
      { id: 2, name: 'Udeme Jonah', title: 'Product designer' },
      { id: 3, name: 'Faith Okoli', title: 'Product designer' },
    ],
    title_icon: dropLogo,
    preview:
      'https://www.figma.com/design/4Ry0qztZJTCq9GGZPEqvIC/Wordlift?node-id=0-1&t=Fsi0ZTfaxdPrnT94-0',
    project_overview:
      'The Figma design aimed at enhancing personal relationships, WordLift, reminds users to express love and appreciation with timely affirmations, ensuring they communicate effectively even when finding words challenging.',
  },
  {
    id: 'post-note',
    link: '/post-note/project',
    name: 'post-note',
    title: 'Post-note',
    description:
      'A web app to help you connect through the exchange of personal notes.',
    tag: 'Drop',
    isProduct: true,
    icon: dropLogo,
    images: [
      {
        id: 1,
        src: notes1,
      },
      {
        id: 2,
        src: notes2,
      },
    ],
    prev: '/wordlift/project',
    next: '/couple-suite/project',
    date: 'June 2024',
    contributors: [
      { id: 1, name: 'Shammah Nei', title: '' },
      { id: 2, name: 'Favour Idogun', title: 'Data Analyst' },
    ],
    title_icon: dropLogo,
    preview: 'https://post-note-demo.vercel.app/',
    project_overview:
      'The Post Notes project aims to foster meaningful connections through the exchange of personal notes. Users can appreciate someone, express concerns, or simply stay in touch, all within a constructive and secure environment for communication.',
  },
  {
    id: 'couple-suite',
    link: '/couple-suite/project',
    name: 'couple-suite',
    title: 'Couple Suite',
    description:
      'A card game designed to strengthen relationships between partners.',
    tag: 'Drop',
    isProduct: true,
    icon: dropLogo,
    images: [
      {
        id: 1,
        src: suite1,
      },

      {
        id: 2,
        src: suite2,
      },
    ],
    prev: '/post-note/project',
    next: '/outyn/project',
    date: 'June 2024',
    contributors: [
      { id: 1, name: 'Samuel Seibidor', title: 'Graphics designer' },
    ],
    title_icon: dropLogo,
    preview:
      'https://github.com/Renaissance-Innovation-Labs/drop-s1-couple-suite/tree/main/exported/PNG',
    project_overview:
      'Couple Suite is a thoughtfully designed card game aimed at strengthening relationships by fostering open communication, empathy, and understanding between partners. By providing a dedicated space for connection, Couple Suite helps partners build a stronger, more fulfilling relationship.',
  },
  {
    id: 'outyn',
    link: '/outyn/project',
    name: 'outyn',
    title: 'OUTYN',
    description: 'A web app  for planning social outings and events.',
    tag: 'Drop',
    isProduct: true,
    icon: dropLogo,
    images: [
      {
        id: 1,
        src: outyn1,
      },
      {
        id: 2,
        src: outyn2,
      },
    ],
    prev: '/couple-suite/project',
    next: '/image-slider/project',
    date: 'June 2024',
    contributors: [
      { id: 1, name: 'Chioma Otu', title: 'Frontend Developer' },
      { id: 2, name: 'Adebayo Sukurat', title: 'Product designer' },
      { id: 3, name: 'Emmanuel Stephen', title: 'Project Manager' },
      { id: 4, name: 'Perpetua Urama', title: 'Project Manager' },
    ],
    title_icon: dropLogo,
    preview: 'https://outynweb-droxys-projects.vercel.app/',
    project_overview:
      'The Outyn app aimed to enhance social interaction and enjoyment by simplifying the organization and planning of outings and events. It provided a comprehensive set of tools through both web and mobile platforms, allowing users to plan, schedule, budget, and manage logistics seamlessly.',
  },
  {
    id: 'image-slider',
    link: '/image-slider/project',
    name: 'image-slider',
    title: 'Image Slider',
    description: 'Animated Gallery Slider',
    tag: 'Component',
    isProduct: true,
    icon: code,
    images: [
      {
        id: 1,
        src: imageSlider,
      },
    ],
    prev: '/couple-suite/project',
    next: '/gallery-slider/project',
    date: 'July 2024',
    contributors: [
      {
        id: 1,
        name: 'David Aroh',
        title: 'Front-End Developer',
      },
    ],
    title_icon: code,
    preview: 'https://renaissance-innovation-labs.github.io/dave-hero-slider/',
    project_overview:
      'Image slider effortlessly showcases image collections. Fully responsive, user-friendly design. Easy navigation with user on click or auto play, smooth transitions. Customizable for diverse needs',
  },
  {
    id: 'gallery-slider',
    link: '/gallery-slider/project',
    name: 'gallery-slider',
    title: 'Gallery Slider',
    description: 'Animated Gallery Slider',
    tag: 'Component',
    isProduct: true,
    icon: code,
    images: [
      {
        id: 1,
        src: slider,
      },
    ],
    prev: '/image-slider/project',
    next: '/product-details/project',
    date: 'June 2024',
    contributors: [
      {
        id: 1,
        name: 'Chioma Otu',
        title: 'Front-End Developer',
      },
      {
        id: 2,
        name: 'Nuel Wogundu',
        title: 'UI/UX Designer',
      },
    ],
    title_icon: code,
    preview: 'https://codepen.io/Developer-Renaissance/pen/gONOXVQ',
    project_overview:
      'A responisve animated gallery slider created with NextJs v14 and tailwind css.',
  },
  {
    id: 'product-details',
    link: '/product-details/project',
    name: 'product-details',
    title: 'Product Details',
    description: 'A reusuable component based product detail.',
    tag: 'Component',
    date: 'June 2024',
    isProduct: true,
    icon: code,
    images: [
      {
        id: 1,
        src: pView1,
      },
      {
        id: 2,
        src: pView2,
      },
    ],
    prev: '/gallery-slider/project',
    next: '/ai-paraphraser/project',
    contributors: [
      {
        id: 1,
        name: 'Chioma Otu',
        title: 'Front-End Developer',
      },
      {
        id: 2,
        name: 'Nuel Wogundu',
        title: 'UI/UX Designer',
      },
    ],
    title_icon: code,
    preview: 'https://codepen.io/Developer-Renaissance/pen/qBzBwgM',
    project_overview:
      'A product details view component which has two types of image layout created with NextJs v14 and tailwind css.',
  },
  {
    id: 'ai-paraphraser',
    link: '/ai-paraphraser/project',
    name: 'ai-paraphraser',
    title: 'AI paraphraser',
    description: 'AI technology to help you rephrase sentences and paragraphs.',
    tag: 'Productivity',
    isProduct: true,
    icon: productivity,
    prev: '/product-details/project',
    next: '/pokemon/project',
    contributors: [
      {
        id: 1,
        name: 'Inyang Johnson Inyang',
        title: 'Front-End Developer',
      },
    ],
    title_icon: paraphraser,
    preview: 'https://paraphraser-w8i4-git-main-inyangj.vercel.app/',
    project_overview:
      'The AI Paraphrasing Tool Web App is an easy-to-use online platform that uses smart AI technology to help you rephrase sentences and paragraphs.The tool aims to assist users in rephrasing text while maintaining original meaning and coherence. By leveraging the capabilities of state-of-the-art language models, the web app offers a seamless and efficient solution for generating paraphrased content.',
  },
  {
    id: 'pokemon',
    name: 'pokemon',
    link: '/ai-paraphraser/project',
    title: 'Pokemon Image generator',
    description: 'shows more details about any type of Pokemon using its name.',
    tag: 'Gaming',
    isProduct: true,
    icon: gaming,
    contributors: [
      {
        id: 1,
        name: 'Inyang Johnson Inyang',
        title: 'Front-End Developer',
      },
    ],
    title_icon: pokemon,
    prev: '/ai-paraphraser/project',
    next: '/article-merger/project',

    preview: 'https://poke-app-git-main-inyangj.vercel.app/',
    project_overview:
      'The Pokemon Search Web Page project aimed to create a user-friendly web page where users can search for Pokemon and access detailed information about their chosen Pokemon. The objective was to provide a simple, intuitive platform for Pokemon enthusiasts to easily retrieve information about their favorite creatures.',
  },

  {
    id: 'article-merger',
    name: 'article-merger',
    link: '/article-merger/project',
    title: 'Article Merger',
    description:
      'Leverages the OpenAI API to process user-provided text of two different articles.',
    tag: 'Learning',
    isProduct: true,
    contributors: [
      {
        id: 1,
        name: 'Inyang Johnson Inyang',
        title: 'Front-End Developer',
      },
      {
        id: 2,
        name: 'Obianuju Ofodu',
        title: 'Back-End Developer',
      },

      {
        id: 3,
        name: 'Emmanuel Tammy',
        title: 'Front-End Developer',
      },
    ],
    title_icon: article,
    icon: learning,
    next: '/ai-image-generator/project',
    prev: '/pokemon/project',
    preview: 'https://article-merger-git-main-inyangj.vercel.app/',
    project_overview:
      'The Article Merger project aimed to create a user-friendly web application utilizing the OpenAI API to process user-provided text/link from two different articles. Users were able to specify their desired outcomes or information through prompts. The application fetched and analyzed the content of the articles and provided users with relevant information based on their input.',
  },

  {
    id: 'ai-image-generator',
    name: 'ai-image-generator',
    link: '/ai-image-generator/project',
    title: 'AI Image generator',
    description:
      'The app interprets the input text and produces corresponding images that match the description',
    tag: 'Graphics',
    isProduct: true,
    icon: graphics,
    contributors: [
      {
        id: 1,
        name: 'Ini Michael',
        title: 'Front-End Developer',
      },
    ],
    title_icon: imageGenerator,
    prev: '/article-merger/project',
    next: '/todo-list/project',
    preview:
      'https://vercel.com/inimichael/ai-image-generator/2WAUdS3QK1HQX5ZANPxBtAKYY9gv',
    project_overview:
      'The Article Merger project aimed to create a user-friendly web application utilizing the OpenAI API to process user-provided text/link from two different articles. Users were able to specify their desired outcomes or information through prompts. The application fetched and analyzed the content of the articles and provided users with relevant information based on their input.',
  },

  {
    id: 'todo-list',
    name: 'todo-list',
    link: '/todo-list/project',
    title: 'To-do List',
    description: 'A minimal to-do app: add, complete, delete tasks.',
    tag: 'Productivity',
    isProduct: true,
    icon: productivity,
    contributors: [
      {
        id: 1,
        name: 'Ini Michael',
        title: 'Front-End Developer',
      },
    ],
    title_icon: todo,
    prev: '/ai-image-generator/project',
    next: '/eventbuddy/project',
    preview:
      'https://vercel.com/inimichael/ai-image-generator/2WAUdS3QK1HQX5ZANPxBtAKYY9gv',
    project_overview:
      "The Simple To-Do Web Application project aimed to create an intuitive web-based platform where users can create, mark as done, and delete tasks. Additionally, users can view their previous tasks that haven't been deleted. The project provided a straightforward solution for users to manage their to-do lists efficiently.",
  },

  {
    id: 'event-buddy',
    name: 'eventbuddy',
    link: '/eventbuddy/project',
    title: 'EventBuddy',
    description:
      'Event registration tool with automatic follow-up. Easy event creation and secure data storage.',
    tag: 'Productivity',
    isProduct: true,
    icon: productivity,
    prev: '/todo-list/project',
    contributors: [
      {
        id: 1,
        name: 'Michael Ukpeh',
        title: 'Back-End Developer',
      },
      {
        id: 2,
        name: 'Inyang Johnson',
        title: 'Front-End Developer',
      },

      {
        id: 3,
        name: 'Ini Michael',
        title: 'Front-End Developer',
      },

      {
        id: 4,
        name: 'Nicholas',
        title: 'Front-End Developer',
      },

      {
        id: 5,
        name: 'Okonu Deborah',
        title: 'Project Manager',
      },
    ],
    title_icon: eventBuddy,
    next: '/geolocation-validator/project',
    preview: 'https://event-buddy-ruby.vercel.app/',
    project_overview:
      'EventBuddy was developed with the primary focus on event registration and automated follow-up messaging. This tool allows users to effortlessly create and register for events or special occasions, with their details securely stored in a database. The system also integrates the OpenAI API for the automatic generation and sending of follow-up messages to registered users.',
  },

  {
    id: 'geo-location',
    link: '/geolocation-validator/project',
    name: 'geolocation-validator',
    title: 'Geolocation Validator Package',
    description: 'shows more details about any type of Pokemon using its name.',
    tag: 'Productivity',
    isProduct: true,
    icon: productivity,
    contributors: [
      {
        id: 1,
        name: 'Michael Ukpeh',
        title: 'Back-End Developer',
      },
    ],
    title_icon: geolocation,
    prev: '/eventbuddy/project',
    next: '/grimoire/project',
    preview: 'https://github.com/Michael2004-ukpeh/geolocation-validator',
    project_overview:
      "The Simple To-Do Web Application project aimed to create an intuitive web-based platform where users can create, mark as done, and delete tasks. Additionally, users can view their previous tasks that haven't been deleted. The project provided a straightforward solution for users to manage their to-do lists efficiently.",
  },

  {
    id: 'grimoire',
    link: '/grimoire/project',
    name: 'grimoire',
    title: 'Grimoire',
    description:
      'Interactive story game app where user choices drive the plot.',
    tag: 'Gaming',
    isProduct: true,
    icon: gaming,
    contributors: [
      {
        id: 1,
        name: 'Josephine Abel',
        title: 'Front-End Developer',
      },
      {
        id: 2,
        name: 'Favour John',
        title: 'Back-End Developer',
      },
    ],
    title_icon: grimoire,
    prev: '/geolocation-validator/project',
    next: '/ai-chatbot/project',
    preview: 'https://grimoire-cem3-l4515qdhh-jowwy02.vercel.app/',
    project_overview:
      'The Interactive Fiction Game App project aimed to create an engaging and interactive storytelling experience for users. The application presents users with a narrative and offers choices that lead to different story branches. This project provided an immersive storytelling platform that allowed users to shape the course of the story based on their decisions.',
  },

  {
    id: 'ai-chatbot',
    link: '/ai-chabot/project',
    name: 'ai-chatbot',
    title: 'AI Chat Bot',
    description:
      "an AI-powered chatbot website similar to OpenAI's GPT-3.5 model.",
    tag: 'Productivity',
    isProduct: true,
    icon: productivity,
    prev: '/grimoire/project',
    next: '/symptom-checker/project',
    contributors: [
      {
        id: 1,
        name: 'Josephine Abel',
        title: 'Front-End Developer',
      },
    ],
    title_icon: chatBox,
    preview: 'https://chat-bot-ten-kappa.vercel.app/',
    project_overview:
      "The AI-Powered Chatbot Website project aimed to create a simple and user-friendly platform equipped with an AI-powered chatbot similar to OpenAI's GPT3.5 model. This chatbot allowed users to ask questions on a wide range of topics and receive AI-generated responses in a conversational manner. The project provided an intuitive interface for users to engage with the chatbot and obtain informative and interactive responses",
  },

  {
    id: 'symptom-checker',
    name: 'symptom-checker',
    link: '/symptom-checker/project',
    title: 'Symptom Checker',
    description:
      'An AI-powered application that provides health-related assistance to users.',
    tag: 'Health',
    isProduct: true,
    icon: health,
    prev: '/ai-chatbot/project',
    next: '/findive/project',
    contributors: [
      {
        id: 1,
        name: 'Obianuju Ofodu',
        title: 'Back-End Developer',
      },
      {
        id: 2,
        name: 'Favour John',
        title: 'Front-End Developer',
      },
    ],
    title_icon: symptomChecker,
    preview: 'https://omasjhn.github.io/symptom-checker/',
    project_overview:
      'The Symptom checker project aimed to develop an AI-powered application that provides health-related assistance to users. This application offers symptom checking and medical advice, including possible treatment methods, based on user input. It integrates Python with Open AI APIs to ensure the provision of accurate information. The Symptom checker Web App is designed to empower users to take charge of their health and make informed decisions.',
  },
  {
    id: 'findive',
    link: '/findive/project',
    name: 'findive',
    title: 'FinDive',
    description:
      'An AI Powered web app for detailed financial statement analysis.',
    tag: 'Productivity',
    isProduct: true,
    icon: productivity,
    contributors: [
      {
        id: 1,
        name: 'Falana Elijah',
        title: 'Front-End Developer',
      },
      {
        id: 2,
        name: 'Cedar Daniel',
        title: 'Back-End Developer',
      },
      {
        id: 3,
        name: 'Perperual Urama',
        title: 'Programs Manager',
      },
    ],
    title_icon: findive,
    prev: '/symptom-checker/project',
    next: '/ai-recommendation/project',
    preview: 'https://bank-statement-data-analyzer.vercel.app/',
    project_overview:
      "FinDive project aimed to create a user-friendly platform for comprehensive analyses of users' bank statements or business income and expense documents. Through integration with the OpenAI API, the app was designed to extract, process, and analyze data, providing personalized financial summaries and recommendations.",
  },
  {
    id: 'ai-recommendation',
    link: '/ai-recommendation/project',
    title: 'AI Recommendation System',
    name: 'ai-recommendation',
    description:
      'Smart app for discovering new interests based on your preferences',
    tag: 'Learning',
    isProduct: true,
    icon: learning,
    prev: '/findive/project',
    next: '/ecommerce-website/project',
    contributors: [
      {
        id: 1,
        name: 'Emmanuel Tammy',
        title: 'Front-End Developer',
      },
    ],
    title_icon: aiRecommend,
    preview: 'https://recom-zen-guide.vercel.app/Home',
    project_overview:
      "The AI Recommendation System project aimed to develop an intelligent application that assists users in discovering new hobbies, books, movies, music, and other interests based on their preferences. By employing advanced machine learning techniques, the system analyzed the user's selected hobbies to generate personalized recommendations aligned with their unique tastes. The system provided an interactive and user-friendly interface for users to easily select their favorite hobbies and receive tailored suggestions.",
  },
  // last item should have next as null
  {
    id: 'ecommerce-website',
    link: '/ecommerce-website/project',
    name: 'ecommerce-website',
    title: 'E-commerce Website',
    description:
      'Basic e-commerce site: view, add to cart, checkout, and see cart contents.',
    tag: 'Productivity',
    isProduct: true,
    icon: productivity,
    prev: '/ai-recommendation/project',
    next: null,
    contributors: [
      {
        id: 1,
        name: 'Emmanuel Tammy',
        title: 'Front-End Developer',
      },
    ],
    title_icon: ecommerce,

    preview: 'https://ecommerce-cart-product-system.vercel.app/Home',
    project_overview:
      'The Simple E-Commerce Website project aimed to create a user-friendly online platform where users can view available goods for sale, add them to their cart, and proceed to checkout. Additionally, users can view the contents of their cart before finalizing their purchases. The project provided a seamless solution for users to engage in e-commerce activities.',
  },
];

export const articles = [
  {
    id: 1,
    date: 'Sept 8, 2023',
    link: 'https://blog.renaissancelabs.org/pulling-your-own-weight',
    title: 'Pulling your own weight',
    description:
      "As a tech professional, you probably spend most of your day sitting in front of a computer. While this may be great for your career, it's not so great...",
    tag: 'Fitness',
    isProduct: false,
  },
  {
    id: 2,
    date: 'August 17, 2023',
    link: 'https://blog.renaissancelabs.org/why-choose-rilearn-bootcamp-for-skill-development-unlocking-innovation-and-tech-expertise',
    title: 'Unlocking Innovation and Tech Expertise',
    description:
      "In today's rapidly evolving world, staying ahead requires constant upskilling and innovation. RILearn Bootcamp stands as a beacon of...",
    tag: 'Learning',
    isProduct: false,
  },

  {
    id: 3,
    date: 'February 7, 2023',
    link: 'https://blog.renaissancelabs.org/10-collaborative-tools-to-improve-your-coworking-experience',
    title: 'Collaborative Tools to Improve Your Coworking Experience',
    description:
      'The rise in remote and hybrid work in recent years has led to a consistent search for ways to improve productivity...',
    tag: 'Collaboration',
    isProduct: false,
  },

  {
    id: 4,
    date: 'August 17, 2023',
    link: 'https://blog.renaissancelabs.org/thriving-in-the-new-normal-strategies-for-tech-companies-during-an-economic-downturn',
    title: 'Strategies for Tech Companies During an Economic Downturn',
    description:
      'In the face of the current economic downturn affecting various industries, including the tech sector, companies...',
    tag: 'Learning',
    isProduct: false,
  },
];

export const contributors = [
  {
    id: 1,
    name: 'Inyang Johnson Inyang',
    title: 'Engineering',
  },
  {
    id: 2,
    name: 'Ini Michael',
    title: 'Engineering',
  },

  {
    id: 3,
    name: 'Josephine Abel',
    title: 'Engineering',
  },
  {
    id: 4,
    name: 'Ukpeh Michael',
    title: 'Engineering',
  },
  {
    id: 5,
    name: 'Obianuju Ofodu',
    title: 'Engineering',
  },
  {
    id: 6,
    name: 'Emmanuel Tammy',
    title: 'Engineering',
  },

  {
    id: 7,
    name: 'Siki Tekena',
    title: 'PR & Marketing',
  },

  {
    id: 8,
    name: 'Okonu Deborah',
    title: 'Project Manager',
  },
  {
    id: 9,
    name: 'Perpetua Urama',
    title: 'Program Manager',
  },
  {
    id: 10,
    name: 'John Favour',
    title: 'Engineering',
  },
  {
    id: 11,
    name: 'Falana Elijah',
    title: 'Engineering',
  },

  {
    id: 12,
    name: 'Ebi Spiff',
    title: 'Design',
  },
  {
    id: 13,
    name: 'Cedar Daniel',
    title: 'Engineering',
  },
  {
    id: 14,
    name: 'Shammah Nei',
    title: 'Engineering',
  },
  {
    id: 15,
    name: 'Chioma Otu',
    title: 'Engineering',
  },
  {
    id: 16,
    name: 'Ayaosi Godfrey',
    title: 'Product Manager',
  },

  {
    id: 17,
    name: 'Nuel Wogundu',
    title: 'Design',
  },
];

export const footItem1 = [
  {
    id: 1,
    text: 'Our Playground',
    link: `/${routes.overview}?rilq=playground`,
    icon: null,
  },
  {
    id: 2,
    text: 'Our Community',
    icon: '/',
    link: 'https://renaissancelabs.org/community',
  },

  {
    id: 3,
    text: 'Our Blog',
    link: 'https://blog.renaissancelabs.org/',
    icon: null,
  },

  {
    id: 4,
    text: 'Build with us',
    link: 'https://renaissancelabs.org/services',
    icon: null,
  },

  {
    id: 5,
    text: 'Become an investor',
    link: 'https://github.com/sponsors/Renaissance-Innovation-Labs?o=esb',
    icon: null,
  },
];

export const footItem2 = [
  {
    id: 1,
    text: 'Terms and conditions',
    link: '/',
    icon: null,
  },
  {
    id: 2,
    text: 'Privacy Policy',
    link: '/',
    icon: null,
  },

  {
    id: 3,
    text: 'RIL Talent Pool',
    link: '/',
    icon: goLink,
  },
];
